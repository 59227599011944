<template>
    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Users</h5>

                        <!-- Default Tabs -->
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="students-tab" data-bs-toggle="tab"
                                    data-bs-target="#students" type="button" role="tab" aria-controls="students"
                                    aria-selected="true">Students</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="mentors-tab" data-bs-toggle="tab" data-bs-target="#mentors"
                                    type="button" role="tab" aria-controls="mentors"
                                    aria-selected="false">Mentors</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="admin-tab" data-bs-toggle="tab" data-bs-target="#admin"
                                    type="button" role="tab" aria-controls="admin" aria-selected="false">Admins</button>
                            </li>
                        </ul>
                        <div class="tab-content pt-2" id="myTabContent">
                            <div class="tab-pane fade show active" id="students" role="tabpanel"
                                aria-labelledby="students-tab">
                                <h3 class="mt-1 mb-3">List of students</h3>
                                <hr />
                                <table class="table table-responsive mt-3" v-if="loading">
                                    <tr>
                                        <td>
                                            <h5>Loading list of students ...</h5>
                                        </td>
                                    </tr>
                                </table>
                                <table class="table table-responsive" v-else>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Names</th>
                                            <th>Email</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(student, index) in students" :key="student.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ student.lastname }} {{ student.firstname }}</td>
                                            <td>{{ student.email }}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane fade" id="mentors" role="tabpanel" aria-labelledby="mentors-tab">
                                <h3 class="mt-1 mb-3">List of mentors</h3>
                                <hr />
                                <table class="table table-responsive mt-3" v-if="loading">
                                    <tr>
                                        <td>
                                            <h5>Loading list of mentors ...</h5>
                                        </td>
                                    </tr>
                                </table>
                                <table class="table table-responsive" v-else>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Names</th>
                                            <th>Email</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(mentor, index) in mentors" :key="mentor.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ mentor.lastname }} {{ mentor.firstname }}</td>
                                            <td>{{ mentor.email }}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane fade" id="admin" role="tabpanel" aria-labelledby="admin-tab">
                                <h3 class="mt-1 mb-3">List of Admin Users</h3>
                                <hr />
                                <table class="table table-responsive mt-3" v-if="loading">
                                    <tr>
                                        <td>
                                            <h5>Loading list of admin users ...</h5>
                                        </td>
                                    </tr>
                                </table>
                                <table class="table table-responsive" v-else>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Names</th>
                                            <th>Email</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(admin, index) in admins" :key="admin.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ admin.lastname }} {{ admin.firstname }}</td>
                                            <td>{{ admin.email }}</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div><!-- End Default Tabs -->

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            students: [],
            mentors: [],
            admins: [],
            loading: false,
        }
    },
    methods: {
        async getStudents() {
            this.loading = true;
            let response = await axios.get('/students');
            this.students = response.data;
            this.loading = false;
        },
        async getMentors() {
            this.loading = true;
            let response = await axios.get('/mentors');
            this.mentors = response.data;
            this.loading = false;
        },
        async getAdmins() {
            this.loading = true;
            let response = await axios.get('/admins');
            this.admins = response.data;
            this.loading = false;
        },
    },
    created() {
        this.getStudents();
        this.getMentors();
        this.getAdmins();
    }
}
</script>