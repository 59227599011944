<template>
    <section class="section">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title bg-primary text-white mt-3 p-2">Club Name: {{ club.club_name }}</h5>
                        <!-- Default Accordion -->
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <b>Club Members</b>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show"
                                    aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <!-- Display Club Members -->
                                        <table class="table">
                                            <thead>
                                                <th>#</th>
                                                <th>Name</th>
                                            </thead>
                                            <tbody v-if="club.students">
                                                <tr v-for="(student, index) in club.students" :key="student.id">
                                                    <td>{{ index + 1 }}</td>
                                                    <td>{{ student.firstname }} {{ student.lastname }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <strong>Club Leader</strong>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div v-if="club.leader">
                                            <h5> {{ club.leader.firstname }} {{ club.leader.lastname }}</h5>

                                            <button class="btn btn-warning btn-sm mt-2" @click="openModal(club)">Edit
                                                Leader</button>
                                        </div>
                                        <div v-else>
                                            <h3>
                                                No leader assigned
                                            </h3>
                                            <button class="btn btn-primary btn-sm mt-2" @click="openModal(club)">Assign
                                                Leader</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        <strong>Club Mentor(s)</strong>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse"
                                    aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div v-if="club.mentors">
                                            <ul>
                                                <li v-for="mentor in club.mentors" :key="mentor.id">{{ mentor.firstname
                                                    }} {{ mentor.lastname }}</li>
                                            </ul>
                                        </div>
                                        <div v-else>
                                            <h3>No Mentor assigned</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!-- End Default Accordion Example -->
                    </div>
                </div>

            </div>
        </div>
        <!-- Modal for assigning/editing club leader -->
        <div v-if="showModal" class="modal fade show" style="display: block; background: rgba(0,0,0,0.5);">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Assign/Edit Club Leader</h5>
                        <button type="button" class="btn-close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <select class="form-control" id="leaderSelect" v-model="selectedLeaderId" style="width:100%;">
                            <option disabled value="">Select a Leader</option>
                            <option v-for="student in club.students" :key="student.id" :value="student.id">
                                {{ student.firstname }} {{ student.lastname }}
                            </option>
                        </select>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                        <button type="button" class="btn btn-primary" @click="saveLeader">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <router-link to="/admin/clubs" class="btn btn-primary btn-sm">Back</router-link>

</template>

<script>
import axios from 'axios';

export default {
    props: ['id'], // The club ID passed from the route

    data() {
        return {
            club: {}, // To store the club details
            selectedLeaderId: null,
            showModal: false,
            selectedClub: null,
        };
    },

    mounted() {
        this.fetchClubDetails();
    },

    methods: {
        async fetchClubDetails() {
            try {
                const response = await axios.get(`/clubs/${this.id}`); // Fetch club details by id
                this.club = response.data;
                console.log(this.club);
            } catch (error) {
                console.error('Error fetching club details:', error);
            }
        },

        openModal(club) {
            this.selectedClub = club;
            this.selectedLeaderId = club.leader ? club.leader.id : null;
            this.showModal = true;

        },
        closeModal() {
            this.showModal = false;
            this.selectedClub = null;
            this.selectedLeaderId = null;
        },

        editMentor() {
            // Logic to edit the mentor (could open a modal for selection)
        },
        async assignLeader() {
            try {
                const response = await axios.post('/clubs/assign-leader', {
                    leader_id: this.selectedLeader,
                });
                this.club.leader = response.data.leader;
                console.log('Leader assigned:', response.data);

                const modal = bootstrap.Modal.getInstance(document.getElementById('editLeaderModal'));
                modal.hide();
            } catch (error) {
                console.error('Error assigning leader: ', error);
            }
        },
        saveLeader() {
            // Save the selected leader
            if (!this.selectedLeaderId) {
                alert("Please, select a leader.");
                return;
            }
            axios.post('/clubs/assign-leader', {
                club_id: this.club.club_id,
                student_id: this.selectedLeaderId,
            })
                .then((response) => {
                    this.closeModal();
                    this.fetchClubDetails(); // Refresh club details after assigning leader
                })
                .catch((error) => {
                    alert("An error occurred: " + error.response.data.message);
                });
        },
    },
};
</script>