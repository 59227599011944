<template>
    <section class="section profile">
        <div class="row mt-3">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body pt-3">
                        <!-- Bordered Tabs -->
                        <ul class="nav nav-tabs nav-tabs-bordered">
                            <li class="nav-item">
                                <button class="nav-link active" data-bs-toggle="tab"
                                    data-bs-target="#clubs-tab">Clubs</button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" data-bs-toggle="tab"
                                    data-bs-target="#sport-tab">Sports</button>
                            </li>


                            <li class="nav-item">
                                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#action-plan-tab">
                                    Action Plan</button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#community-service-tab">
                                    Community Service</button>
                            </li>
                            <li class="nav-item">
                                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#projects-tab">
                                    Club Projects</button>
                            </li>
                        </ul>
                        <div class="tab-content pt-2">
                            <div class="tab-pane fade show active clubs-tab pt-3" id="clubs-tab"> <!-- Clubs Tab -->
                                <div class="row">
                                    <div class="col-lg-7">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card recent-sales overflow-auto">
                                                    <div class="card-body">
                                                        <h5 class="card-title">Available Clubs </h5>
                                                        <ClubList />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5">

                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="card-title">Your Clubs and Clubmates</h5>
                                                <div class="activity">
                                                    <div class="activity-item d-flex">
                                                        <div class="activity-content">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Club</th>
                                                                        <th>Members</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody v-if="loading">
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <h3>Loading Member ...</h3>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody v-else>
                                                                    <template
                                                                        v-if="Object.keys(clubMembers).length === 0">
                                                                        <tr>
                                                                            <td colspan="2">
                                                                                <h3>No members found</h3>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-else>
                                                                        <tr v-for="(members, clubName) in clubMembers"
                                                                            :key="clubName">
                                                                            <td>{{ clubName }}</td>
                                                                            <td>
                                                                                <ol v-if="members.length > 0">
                                                                                    <li v-for="member in members"
                                                                                        :key="member.student_id">
                                                                                        {{ member.firstname }} {{
                                                                                            member.lastname }} ({{
                                                                                            member.class_level }})
                                                                                    </li>
                                                                                </ol>
                                                                                <p v-else>No member found for this club
                                                                                </p>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div><!-- End Bordered Tabs -->
                        <div class="tab-content pt-2">
                            <div class="tab-pane fade  pt-3" id="sport-tab">
                                <!-- Import Register Sport -->
                                <RegisterSport />

                            </div>
                        </div><!-- End Bordered Tabs -->
                        <div class="tab-content pt-2">
                            <div class="tab-pane fade pt-3" id="action-plan-tab">
                                <ClubActionPlan />
                            </div>
                        </div>
                        <div class="tab-content pt-2">
                            <div class="tab-pane fade pt-3" id="community-service-tab">
                                <h6>Community service feature coming soon ...</h6>
                            </div>
                        </div>
                        <div class="tab-content pt-2">
                            <div class="tab-pane fade pt-3" id="projects-tab">
                                <h6>Projects feature coming soon ...</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
import axios from 'axios';
import ClubList from '@/views/components/ClubList.vue';
import ClubRegistration from '@/views/components/ClubRegistration.vue';
import RegisterSport from '../RegisterSport.vue';
import ClubActionPlan from '../components/ClubActionPlan.vue';
export default {
    name: "List of Clubs",
    components: {
        ClubList,
        ClubRegistration,
        RegisterSport,
        ClubActionPlan,
    },

    data() {
        return {
            //sports: [], // This will hold the list of sports
            selectedSport: null, // To hold the selected sport ID
            registeredSport: null,
            sportMates: [], // This will hold the list of sport-mates after registration
            clubMembers: {},
            loading: false,
        };
    },
    methods: {
        // fetchSports() {
        //     //this.loading=true;
        //     axios.get('/sports').then(response => {
        //         this.sports = response.data;
        //         //this.loading=false;
        //     });

        // },
        fetchClubMembers() {
            axios.get('/club/members').then(response => {
                const groupedMembers = response.data.reduce((acc, member) => {
                    if (!acc[member.club_name]) {
                        acc[member.club_name] = [];
                    }
                    acc[member.club_name].push(member);
                    return acc;
                }, {});

                this.clubMembers = groupedMembers;
                console.log(this.clubMembers);
            });
        },

    },
    created() {
        // this.fetchSports();
        this.fetchClubMembers();
    },
};
</script>