import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView1.vue'
import Profile from '@/views/pages/Profile.vue';
import MentorDashboard from '@/views/pages/MentorDashboard.vue';
import StudentDashboard from '@/views/pages/StudentDashboard.vue';
import StudentLayout from '@/views/pages/StudentLayout.vue';
import ClubList from '@/views/components/ClubList.vue';
import Clubs from '@/views/pages/admin/Clubs.vue';
import AdminLayout from '@/views/pages/admin/AdminLayout.vue';
import AdminDashboard from '@/views/pages/admin/AdminDashboard.vue';
import ClubLeaders from '@/views/pages/admin/ClubLeaders.vue';
import Mentorship from '@/views/pages/admin/Mentorship.vue';
import MentorLayout from '@/views/mentors/MentorLayout.vue';
import ClubDetails from '@/views/pages/admin/ClubDetails.vue';
import Login from '../views/Auth/Login.vue';
import Register from '../views/Auth/Register.vue';
import Users from '@/views/pages/admin/Users.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/login', 
    name:'Login', 
    component: Login,
  },

  {
    path: '/register', 
    name:'Register', 
    component: Register,
  },
  {
    path: '/profile_complete', 
    name:'Profile', 
    component: Profile,
  },
  {
    path: '/mentor-dashboard', 
    name:'MentorDashboard', 
    component: MentorDashboard,
  },
  {
    path: '/student', 
    name:'Student', 
    component: StudentLayout,
    meta:{
      requireAuth: true,
      role: 1
    },
    children:[
      { path: '/student/dashboard', name: 'student.dashboard', component: StudentDashboard},
      { path: '/student/clubs', name: 'Clubs', component: ClubList},
    ],
  },
  {
    path: '/private', 
    name:'Private', 
    component: AdminLayout,
    meta:{
      requireAuth: true,
      role: 3
    },
    children:[
      { path: '/admin/dashboard', name: 'admin.dashboard', component: AdminDashboard},
      { path: '/admin/clubs', name: 'admin.clubs', component: Clubs},
      { path: '/admin/club/leaders', name: 'Club.leaders', component: ClubLeaders},
      { path: '/admin/club/{id}', name: 'Club.members', component: ClubLeaders},
      { path: '/admin/club/mentors', name: 'club.mentors', component: Mentorship},
      { path: '/admin/club/:id', name: 'clubDetails', component: ClubDetails, props: true},
      { path: '/admin/users', name:'admin.users', component: Users}
    ],
  },

  {
    path: '/private/mentor', 
    name:'Private.Mentor', 
    component: MentorLayout,
    meta:{
      requireAuth: true,
      role: 2
    },
    children:[
      { path: '/mentor/dashboard', name: 'mentor.dashboard', component: MentorDashboard},
      
    ],
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const userRole = parseInt(localStorage.getItem('user_role'), 10);

  if(to.matched.some(record => record.meta.requireAuth)) {
    if(!token){
      next({name: 'Login'});
    }else{
      if(to.meta.role && to.meta.role !== userRole){
        if(userRole === 1){
          next({name: 'student.dashboard'});
        }else if(userRole === 2){
          next({name: 'mentor.dashboard'});
        }else if(userRole === 3){
          next({name: 'admin.dashboard'});
        }else{
          next({ name:'Login'});
        }
      }else{
        next();
      }
    }
  }else{
    next();
  }
}); 


export default router
