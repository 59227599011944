<template>
    <div>

        <!-- Only show if the user is a club leader -->
        <div v-if="!isClubLeader">
            <h5>Action plan posted by the club leader appears after being reviewed and approved by the club mentor</h5>
        </div>
        <div v-if="isClubLeader">
            <button @click="showCreatePlan = true" class="btn btn-success btn-sm">Create Action Plan</button>
        </div>

        <!-- List of Approved Action Plans -->

        <table class="table mt-5" v-if="actionPlans.length === 0">

            <tbody>
                <tr>
                    <td colspan="4">
                        <h5>No Action plan is available yet. Check again sometime later</h5>
                    </td>
                </tr>
            </tbody>
        </table>
        <table class="table mt-5" v-else>
            <thead>
                <th>Club</th>
                <th>Download</th>
                <th>Status</th>
                <th>Term</th>
            </thead>
            <tbody>
                <tr v-for="plan in actionPlans" :key="plan.id">
                    <td>Club: {{ plan.club.name }}</td>
                    <td><a :href="plan.file_path" target="_blank">Download</a></td>
                    <td>Status: {{ plan.status }}</td>
                    <td>Term: {{ plan.term }}</td>
                </tr>
            </tbody>
        </table>

        <!-- Create Action Plan Form -->
        <div v-if="showCreatePlan">
            <form @submit.prevent="submitPlan">
                <input type="file" @change="handleFileUpload" accept=".doc,.docx,.pdf" />
                <button type="submit" class="btn btn-primary btn-sm mt-3">Submit</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
    components: {
        QuillEditor,
    },

    data() {
        return {
            selectedFile: null,
            isClubLeader: false,
            showCreatePlan: false,
            content: '',
            actionPlans: [],
            clubs: [],
            selectedClub: null,
            loading: false,

        };
    },
    mounted() {
        this.checkIfClubLeader();
        this.fetchActionPlans();
    },
    methods: {
        checkIfClubLeader() {
            // Check if the current user is the leader of the club
            axios.get(`/clubs/is-leader`)
                .then(response => {
                    this.isClubLeader = response.data.isLeader;
                    this.clubs = response.data.clubs;

                    if (this.clubs.length === 1) {
                        this.selectedClub = this.clubs[0].club_id;
                    }
                })
                .catch(error => {
                    console.error('Error checking if the user is a leader:', error);
                    this.isClubLeader = false
                })
        },
        handleFileUpload(event) {
            this.selectedFile = event.target.files[0];
        },
        fetchActionPlans() {
            // Fetch approved action plans for the club

            axios.get('/action-plans')
                .then(response => {
                    this.actionPlans = response.data;
                });
        },
        async submitPlan() {
            if (!this.selectedFile) {
                alert('Please select a file');
                return;
            }

            const formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('club_id', this.selectedClub);

            try {
                const response = await axios.post('/action-plans/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log('Action Plan Uploaded:', response.data);
            } catch (error) {
                console.log('Error uploading action plan:', error);
            };
        }
    }
};
</script>
<style scoped>
@import '~quill/dist/quill.snow.css';
</style>