<template>
    <div id="app">
        <!-- Header Section with Links -->
        <header class="hero">
            <div class="container">
                <nav class="main-nav">
                    <ul>
                        <li><router-link class="active" to="/">Home</router-link></li>
                        <li><router-link to="/register">Register</router-link></li>
                        <li><router-link to="/login">Login</router-link></li>
                    </ul>
                </nav>
                <h1>Welcome to Gashora Girls Academy</h1>
                <p>Empowering girls through education, leadership, and opportunity.</p>
                <a href="#learn-more" class="cta-btn">Learn More</a>
            </div>
        </header>

        <!-- Sidebar (Mobile) -->
        <div :class="['sidebar', { active: isSidebarActive }]" id="sidebar">
            <nav>
                <ul>
                    <li><router-link class="active" to="/">Home</router-link></li>
                    <li><router-link to="/register">Register</router-link></li>
                    <li><router-link to="/login">Login</router-link></li>
                </ul>
            </nav>
        </div>

        <!-- Sidebar Toggle Button (Mobile) -->
        <div class="sidebar-toggle" @click="toggleSidebar" id="sidebarToggle">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <!-- Main Content -->
        <main class="main-content">
            <section id="learn-more">
                <div class="container">
                    <h2>Empowering Girls through Education</h2>
                    <p>Gashora Girls Academy focuses on providing quality education to young girls, preparing them for
                        leadership in society.</p>
                </div>
            </section>
        </main>

        <!-- Footer -->
        <footer>
            <div class="container">
                <p>© 2024 Gashora Girls Academy. All rights reserved.</p>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isSidebarActive: false
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarActive = !this.isSidebarActive;
        }
    }
};
</script>

<style scoped>
/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f4f4f4;
}

/* Hero Section */
.hero {
    background-image: url('../assets/home/images/ggast18_003.jpg');
    /* Replace with actual image */
    background-size: cover;
    background-position: center;
    height: 100vh;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hero h1 {
    font-size: 3rem;
    text-shadow: 2px 2px 10px rgb(0, 0, 0, 0.7);
}

.hero p {
    font-size: 1.5rem;
    margin: 20px 0;
    text-shadow: 1px 1px 5px rgb(0, 0, 0, 0.7);
}

.cta-btn {
    background-color: #ff6f61;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cta-btn:hover {
    background-color: #ff3e2d;
}

/* Navigation Links in Hero Section */
.main-nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
}

.main-nav ul li {
    display: inline-block;
}

.main-nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0.5);
    /* Transparent background */
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
    /* Text shadow for visibility */

}

.main-nav ul li a:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Main Content */
.main-content {
    padding: 50px 20px;
}

.container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
}

/* Sidebar */
.sidebar {
    display: none;
    background-color: #333;
    color: white;
    height: 100%;
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    padding-top: 60px;
    transition: 0.3s;
}

.sidebar.active {
    left: 0;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    padding: 20px;
    text-align: center;
}

.sidebar ul li a {
    color: white;
    text-decoration: none;
}

.sidebar ul li a:hover {
    background-color: #575757;
}

/* Sidebar Toggle Button */
.sidebar-toggle {
    position: fixed;
    top: 15px;
    left: 15px;
    cursor: pointer;
}

.sidebar-toggle span {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    display: block;
}

/* Footer */
footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .sidebar {
        display: block;
    }

    .sidebar-toggle {
        display: block;
    }
}
</style>