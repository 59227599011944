<template>
    <main>
        <div class="container">
            <section
                class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div class="card mb-3">
                                <div class="card-body">
                                    <div class="pt-2 pb-2">
                                        <h5 class="card-title text-center pb-0 fs-4">Register Your Account</h5>
                                    </div>
                                    <form @submit.prevent="registerUser" method="post" class="row g-3 needs-validation"
                                        novalidate>

                                        <div class="col-12">
                                            <label class="form-label">Email</label>
                                            <div class="input-group has-validation">
                                                <input type="text" v-model="email" class="form-control" required>
                                            </div>
                                            <span v-if="errors.email" class="text-danger">{{ errors.email[0] }} </span>
                                        </div>

                                        <div class="col-12">
                                            <label class="form-label">Password</label>
                                            <div class="input-group has-validation">
                                                <input type="password" v-model="password" class="form-control" required>
                                            </div>
                                            <span v-if="errors.password" class="text-danger">{{ errors.password[0] }}
                                            </span>
                                        </div>

                                        <div class="col-12">
                                            <label class="form-label">Confirm Password</label>
                                            <div class="input-group has-validation">
                                                <input type="password" v-model="password_confirmation"
                                                    class="form-control" required>
                                            </div>
                                            <span v-if="errors.password_confirmation" class="text-danger">{{
                                                errors.password_confirmation[0] }} </span>
                                        </div>
                                        <div class="col-12">
                                            <label class="form-label">Register As</label>
                                            <div class="input-group has-validation">
                                                <div class="btn-group" role="group"
                                                    aria-label="Basic radio toggle button group">
                                                    <input type="radio" class="btn-check" value="1" name="role"
                                                        v-model="role" id="btnradio1" autocomplete="off">
                                                    <label class="btn btn-outline-primary" for="btnradio1">Student
                                                    </label>

                                                    <input type="radio" class="btn-check" value="2" name="role"
                                                        v-model="role" id="btnradio2" autocomplete="off">
                                                    <label class="btn btn-outline-primary" for="btnradio2">Mentor
                                                    </label>
                                                </div>
                                                <span v-if="errors.role" class="text-danger">{{ errors.role[0] }}</span>
                                            </div>

                                            <div v-if="errors.length" class="text-danger mt-2">
                                                <ul>
                                                    <li v-for="(error, key) in errors" :key="error">
                                                        {{ error[0] }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-primary w-100" type="submit" :disabled="loading">
                                                <span v-if="loading" class="spinner-border spinner-border-sm"
                                                    role="status" aria-hidden="true"></span>
                                                <span v-if="!loading">Register</span>
                                            </button>
                                        </div>
                                    </form>
                                    <div class="col-12">
                                        <p class="small mb-0">Already have an account? <a href="/login">Login</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

        </div>
    </main><!-- End #main -->
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            loading: false,
            email: '',
            password: '',
            password_confirmation: '',
            role: '',
            errors: {},
            serverError: null
        }
    },
    methods: {
        async registerUser() {
            this.loading = true;
            this.errors = {};
            this.serverError = null;
            try {
                const response = await axios.post('/register', {
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    role: this.role,
                });

                if (response.status === 200) {
                    await Swal.fire({
                        icon: 'success',
                        title: 'Account created',
                        text: 'Registration successful! Please log in.',
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    setTimeout(() => {
                        this.$router.push('/login');
                    }, 3000);
                }
            } catch (error) {
                this.loading = false;
                if (error.response && error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                } else if (error.response && error.response.status === 401) {
                    this.serverError = error.response.data.message || "Incorrect email or password.";
                } else {
                    this.serverError = 'An error occurred. Please try again later.';
                }
            } finally {
                this.loading = false;
            }
        },
    }
}
</script>