<template>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">School Year</th>
                <th scope="col">Class</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(club, index) in clubRegistrations" :key="club.id">
                <td scope="row">{{ index + 1 }}</td>
                <td>{{ club.name }}</td>
                <td>{{ club.year }}</td>
                <td>{{ club.class_level }}</td>

                <td><button @click="deregisterClub(club.club_id)" class="btn btn-danger btn-sm"><i
                            class="bi bi-x"></i></button></td>
            </tr>
        </tbody>
    </table>
    <div v-if="message" :class="{ 'alert alert-success': success, 'alert alert-danger': !success }">
        {{ message }}
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            clubRegistrations: [],
            user: "",
            message: '',
            success: false,
        }
    },

    methods: {

        async getClubRegistrations() {
            try {
                let response = await axios.get('/student/clubs');
                this.clubRegistrations = response.data.data;
                // this.getClubRegistrations();
            } catch (error) {
                console.error('Error fetching clubs:', error);
                this.message = 'Failed to load clubs.';
                this.success = false;
            }

        },
        async deregisterClub(clubId) {
            if (!confirm('Are you sure you want to deregister from this club?')) {
                return;
            }
            try {
                const response = await axios.post('/deregister', {
                    club_id: clubId,
                });
                this.message = response.data.message;
                this.success = true;

                this.getClubRegistrations();
            } catch (error) {
                console.error('Error deregistering from club:', error);
                this.message = error.response?.data?.message || 'Failed to deregister from the club.';
                this.success = false;
            }
        }
    },
    created() {
        this.getClubRegistrations();
    }
}

</script>