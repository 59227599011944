<template>
    <section class="section profile">
        <div class="row mt-3">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body pt-3">
                        <h3>Clubs and Mentors</h3>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Club Name</th>
                                    <th>Mentors</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(club, index) in clubs" :key="club.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ club.name }}</td>
                                    <td>

                                        <ul v-if="club.mentors && club.mentors.length"
                                            style="list-style-type: none; padding-left: 0; text-align: left;">
                                            <li v-for="mentor in club.mentors" :key="mentor.id">
                                                {{ mentor.firstname }} {{ mentor.lastname }}
                                            </li>
                                        </ul>
                                        <span v-else>No mentor assigned</span>
                                    </td>
                                    <td>
                                        <button v-if="!club.mentors.length" @click="openAssignMentorModal(club)"
                                            class="btn btn-success btn-sm">Assign Mentor</button>
                                        <button v-else @click="openAssignMentorModal(club)"
                                            class="btn btn-warning btn-sm">Re-Assign
                                            Mentor</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <!-- Modal for assigning mentor -->
                        <div v-if="showMentorModal" class="modal">
                            <div class="modal-content">
                                <h4>Assign Mentor(s) {{ selectedClub.name }} Club</h4>
                                <ul style="list-style-type: none;">
                                    <li v-for="mentor in availableMentors" :key="mentor.id">
                                        <input type="checkbox" :value="mentor.id" v-model="selectedMentors" />
                                        {{ mentor.firstname }} {{ mentor.lastname }}
                                    </li>
                                </ul>
                                <button @click="assignMentors" class="btn btn-success btn-sm mt-3">Save</button>
                                <button @click="closeMentorModal" class="btn btn-secondary btn-sm mt-2">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            clubs: [],
            availableMentors: [],
            selectedClub: null,
            selectedMentors: [],
            showMentorModal: false,
        };
    },
    methods: {
        // Fetch clubs and their mentors
        async fetchClubs() {
            try {
                const response = await axios.get('/mentors/clubs-with-mentors');
                this.clubs = response.data;
            } catch (error) {
                console.error("Error fetching clubs", error);
            }
        },
        async openAssignMentorModal(club) {
            this.selectedClub = club;
            try {
                const response = await axios.get('/available-mentors');
                this.availableMentors = response.data;
                this.showMentorModal = true;

            } catch (error) {
                console.error("Error fetching available mentors:", error);
            }
        },

        async assignMentors() {
            try {
                await axios.post('/assign-mentors', {
                    club_id: this.selectedClub.id,
                    mentor_ids: this.selectedMentors,
                });
                this.closeMentorModal();
                this.fetchClubs();
            } catch (error) {
                console.error("Error assigning mentor:", error);
            }
        },

        // Close the modal
        closeMentorModal() {
            this.showMentorModal = false;
            this.selectedMentors = [];
            this.selectedClub = null;
        },
    },
    created() {
        this.fetchClubs(); // Fetch clubs when the component is created
    }
}
</script>
<style scoped>
.modal {
    display: block;
    /* Show the modal */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Dark background */
}

.modal-content {
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    width: 30%;
    border-radius: 8px;
}
</style>
