<template>
    <div class="pagetitle">
        <h1>Dashboard</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active">Dashboard</li>
            </ol>
        </nav>
    </div><!-- End Page Title -->
    <section class="section dashboard">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-xxl-4 col-md-4">
                        <div class="card info-card sales-card">
                            <div class="card-body">
                                <h5 class="card-title"> <span>Students</span></h5>
                                <div class="d-flex align-items-center">
                                    <div
                                        class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                        <i class="bi bi-people"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{{ this.dashboardData.students }}</h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div><!-- End Sales Card -->

                    <!-- Revenue Card -->
                    <div class="col-xxl-4 col-md-4">
                        <div class="card info-card revenue-card">
                            <div class="card-body">
                                <h5 class="card-title"> <span> Clubs</span></h5>
                                <div class="d-flex align-items-center">
                                    <div
                                        class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                        <i class="bi bi-list-stars"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{{ this.dashboardData.clubs }}</h6>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div><!-- End Revenue Card -->

                    <div class="col-xxl-4 col-md-4">
                        <div class="card info-card revenue-card">
                            <div class="card-body">
                                <h5 class="card-title"> <span> Sports</span></h5>

                                <div class="d-flex align-items-center">
                                    <div
                                        class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                        <i class="bi bi-music-player"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{{ this.dashboardData.sports }}</h6>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section">
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <ClubLeaders />

                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <SportLeaders />

                    </div>
                </div>
            </div>
        </div>

    </section>

</template>
<script>
import axios from 'axios';
import ClubLeaders from '@/views/pages/admin/ClubLeaders.vue';
import SportLeaders from '@/views/pages/admin/SportLeaders.vue';
import Clubs from './Clubs.vue';
export default {
    name: "List of Club Leaders",
    components: {
        ClubLeaders,
        SportLeaders,
        Clubs,
    },
    data() {
        return {
            sports: [],
            dashboardData: [],
            showModal: false,
            showModalSport: false,
            form: {
                name: ""
            },
            sport: {
                sport_name: ""
            },
            activeTab: 'home'
        }
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
            this.form.name = "";
        },

        openSportModal() {
            this.showModalSport = true;
        },
        closeSportModal() {
            this.showModalSport = false;
            this.form.name = "";
        },
        async getSports() {
            let response = await axios.get('/sport/student_sports');
            this.sports = response.data;
            console.log(this.sports);
        },
        async registerClub() {
            try {
                await axios.post('/clubs', {
                    name: this.form.name,
                });
                alert("Clubs registered successfully!");
                location.reload();
                this.showModal = false;
            } catch (error) {
                alert("Error registering for clubs");
                console.log(error);
            }
        },

        async registerSport() {
            try {
                await axios.post('/sports', {
                    sport_name: this.sport.sport_name,
                });
                alert("Sport is registered successfully!");
                this.showModalSport = false;
                this.$emit('sport-leaders');


            } catch (error) {
                alert("Error registering a new Sport");
                console.log(error);
            }
        },
        async getDashboardData() {
            let response = await axios.get('/counts/');
            this.dashboardData = response.data;

        },
        setActiveTab(tab) {
            this.activeTab = tab;
            this.$router.push({ query: { tab } });
        }
    },
    created() {
        this.getSports();
        this.getDashboardData();
        const tab = this.$route.query.tab;
        if (tab) {
            this.activeTab = tab;
        }
    },
    watch: {
        '$route.query.tab'(newTab) {
            this.activeTab = newTab || 'home'
        }
    }


}
</script>
<style scoped>
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Optional: Adds a dark background */
}

.modal-dialog {
    margin: auto;
    /* Center the modal horizontally */
}

.modal-content {
    border-radius: 8px;
    /* Optional: Adds rounded corners */
}
</style>