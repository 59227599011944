<template>
    <div>
        <div v-if="registeredSports.length > 0">
            <h3>Registered Sports and members</h3>
            <hr />
            <div v-for="sport in registeredSports" :key="sport.sport_id">
                <h4>List of <b>{{ sport.sport_name }}</b> members</h4>
                <ol>
                    <li v-for="student in sport.students" :key="student.id">{{ student.firstname }} {{
                        student.lastname
                        }}</li>
                </ol>
            </div>
        </div>

        <div v-if="registeredSportCount === 0">
            <h3>Select a sport to register </h3>
            <form @submit.prevent="registerForSport">
                <div v-for="sport in sports" :key="sport.id">
                    <input type="radio" :value="sport.id" v-model="selectedSport" />
                    <label>{{ sport.sport_name }}</label>
                </div>
                <button type="submit">Register</button>
            </form>
        </div>
        <div v-if="registeredSportCount === 1">
            <h4>You can register for a non-primary sport.</h4>
            <form @submit.prevent="registerForSport">
                <div v-for="sport in sports" :key="sport.id">
                    <input type="radio" :value="sport.id" v-model="selectedSport" />
                    <label>{{ sport.sport_name }}</label>
                </div>
                <button type="submit">Register</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            registeredSports: [],
            sports: [],
            selectedSport: null,
            students: [],
            primarySport: null,
            registeredSportCount: 0,
        };
    },
    methods: {
        async fetchSports() {
            const response = await axios.get('/sports');
            this.sports = response.data;
        },
        async fetchRegisteredSports() {
            const response = await axios.get('/sport/registered-with-students');
            this.registeredSports = response.data.sports;
            this.registeredSportCount = response.data.registeredSportsCount;

        },

        async fetchRegisteredSportsCount() {
            const response = await axios.get('/sport/registered-count');
            this.registeredSportCount = response.data.count;
        },
        async fetchStudents(sport_id) {
            const response = await axios.get(`/sport/${sport_id}/students`);
            this.students = response.data;
        },
        async registerForSport() {
            if (this.selectedSport) {
                try {
                    await axios.post('/sport/register', { sport_id: this.selectedSport });
                    await Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Sport registered successfully!',
                        confirmButtonText: 'OK'
                    });
                    this.fetchRegisteredSports(); // Refresh after registration
                } catch (error) {
                    alert(error.response.data.message);
                }
            } else {
                alert('Please select a sport to register.');
            }
        },
    },
    mounted() {
        this.fetchRegisteredSports();
        this.fetchSports();
    },
};
</script>