<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">

        <div class="d-flex align-items-center justify-content-between">
            <a href="index.html" class="logo d-flex align-items-center">
                <img src="@/assets/img/Logo.jpg" alt="">
                <span class="d-none d-lg-block">Extra-Curricula</span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn" @click="toggleSidebar"></i>
        </div>
        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">
                <li class="nav-item dropdown pe-3">
                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{ user.firstname
                            }} {{ user.lastname }}</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                <i class="bi bi-person"></i>
                                <span>My Profile</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <button @click="logout" class="dropdown-item d-flex align-items-center" href="#">
                                <i class="bi bi-box-arrow-right"></i>
                                <span>Sign Out</span>
                            </button>
                        </li>
                    </ul><!-- End Profile Dropdown Items -->
                </li><!-- End Profile Nav -->
            </ul>
        </nav><!-- End Icons Navigation -->
    </header><!-- End Header -->

    <!-- ======= Sidebar ======= -->
    <aside id="sidebar" class="sidebar" :class="['sidebar', { 'sidebar-open': isSidebarOpen }]">
        <button class="close-sidebar-btn" @click="closeSidebar" v-if="isSidebarOpen && windowWidth < 992">
            &times;
        </button>
        <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
                <router-link class="nav-link " :to="{ name: 'admin.dashboard' }">
                    <i class="fas fa-home"></i>
                    <span>Home</span>
                </router-link>
            </li><!-- End Dashboard Nav -->

            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/admin/clubs">
                    <i class="fas fa-robot"></i>
                    <span>Clubs</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/admin/users">
                    <i class="fas fa-users"></i>
                    <span>Users</span>
                </router-link>
            </li>


            <!-- <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/admin/clubs">
                    <i class="bi bi-file-earmark"></i>
                    <span>Sports</span>
                </router-link>
            </li> -->

            <!-- <li class="nav-item">
                <router-link class="nav-link " active-class="active" to="/club/leaders">
                    <i class="bi bi-file-earmark"></i>
                    <span>Club Leaders</span>
                </router-link>
            </li> -->
            <!-- <li class="nav-item">
                <router-link class="nav-link " active-class="active" to="/club/mentors">
                    <i class="bi bi-file-earmark-post"></i>
                    <span>Club Mentorship</span>
                </router-link>
            </li> -->
        </ul>

    </aside><!-- End Sidebar-->

    <main id="main" class="main">

        <section class="section dashboard">
            <router-view />
        </section>

    </main><!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer id="footer" class="footer">
        <div class="copyright">
            &copy; Copyright <strong><span>GGAST Extra Curricular Department</span></strong>. All Rights Reserved
        </div>
    </footer><!-- End Footer -->
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            user: "",
            isSidebarOpen: false,
            windowWidth: window.innerWidth,
        }
    },
    methods: {
        async userProfile() {
            await axios.get("/user").then((response) => {
                this.user = response.data;
                console.log(this.user)
            });
        },

        async logout() {
            try {
                await axios.post('logout');
                this.$router.push('/login');

            } catch (error) {
                console.error("Logout failed: ", error);
            }
        },
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        closeSidebar() {
            this.isSidebarOpen = false;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth >= 992) {
                this.isSidebarOpen = true;
            } else {
                this.isSidebarOpen = false;
            }
        }
    },
    created() {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");
        this.userProfile();

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }

}
</script>
<style scoped>
.nav-link.active {
    font-weight: bold;
    color: #d71414;
    /* Bootstrap's primary color */
}

/* General styles for the header */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: 70px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.main {
    margin-top: 60px;
    transition: margin-top 0.3s ease;
}

/* Sidebar styles */
.sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    width: 18%;
    height: calc(100% - 60px);
    background-color: #f8f9fa;
    z-index: 999;
    transform: translateY(-100%);
    transition: all 0.3s ease;
}

/* Show sidebar when open */
.sidebar-open {
    transform: translateY(0);
}

.toggle-sidebar-btn {
    display: inline-block;
    cursor: pointer;
}

/* Toggle button hidden on large screens */
@media (min-width: 992px) {
    .sidebar {
        transform: translateY(0);
        /* Keep the sidebar open on larger screens */
        left: 0;
        right: auto;
        width: 250px;
        /* Fixed width for sidebar on large screens */
    }

    .toggle-sidebar-btn {
        display: none;
    }

    .close-sidebar-btn {
        display: none;
    }
}

.close-sidebar-btn {
    background: none;
    border: none;
    color: #000;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    display: none;
}

.sidebar-open .close-sidebar-btn {
    display: block;
}
</style>