<template>
    <form @submit.prevent="registerClubs()">
        <div class="form-group">
            <label>Choose a class</label>
            <select class="form-control mb-2" :disabled="exceedsClubLimit" v-model="form.class_level">
                <option>Select Class</option>
                <option>S4 MCB</option>
                <option>S4 MCE</option>
                <option>S4 MEG</option>
                <option>S4 MPC</option>
                <option>S4 MPG</option>
                <option>S4 PCB</option>
                <option>S4 PCM</option>
                <option>S5 MCB</option>
                <option>S5 MCE</option>
                <option>S5 MEG</option>
                <option>S5 MPC</option>
                <option>S5 MPG</option>
                <option>S5 PCB</option>
                <option>S5 PCM</option>
                <option>S6 MCB</option>
                <option>S6 MCE</option>
                <option>S6 MEG</option>
                <option>S6 MPC</option>
                <option>S6 MPG</option>
                <option>S6 PCB</option>
                <option>S6 PCM</option>
            </select>
        </div>
        <p><strong>Select up to two clubs from different categories to register in</strong></p>
        <div v-for="(category) in clubCategories" :key="category.id" class="mb-3">
            <h5>{{ category.category_name }}</h5>

            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Club Name</th>
                    </tr>
                </thead>
                <tbody v-if="loading">
                    <tr>
                        <td colspan="2">
                            <h3>Loading Clubs ....</h3>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <template v-if="category.clubs.length === 0">
                        <tr>
                            <td colspan="2">
                                <h3>No clubs found in this category</h3>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="club in category.clubs" :key="club.id">
                            <td scope="row">
                                <input type="checkbox" :value="club.id" v-model="selectedClubs"
                                    :disabled="alreadyRegisteredClubs.includes(club.id)"
                                    :checked="alreadyRegisteredClubs.includes(club.id)"
                                    @change="checkCategory(club.category_id)">
                            </td>
                            <td>{{ club.name }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <p v-if="categoryError" class="error">{{ categoryError }}</p>

        <p v-if="selectedClubs.length > 2" class="error">
            You can only select up to two clubs from different categories.
        </p>
        <button type="submit" class="btn btn-secondary btn-sm"
            :disabled="selectedClubs.length === 0 || selectedClubs.length > 2 || categoryError !== ''">Register</button>

    </form>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {

    data() {
        return {
            loading: false,
            clubs: [],
            selectedClubs: [],
            alreadyRegisteredClubs: [],
            form: {
                class_level: '',
            },
            clubCategories: [],
            selectedCategories: new Set(),
            categoryError: '',
        }
    },
    methods: {
        async getClubs() {
            this.loading = true;
            try {
                let response = await axios.get('/clubs');
                this.clubCategories = response.data;
                console.log("Fetched Club Categories: ", this.clubCategories);
            } catch (error) {
                console.error("Error fetching clubs: ", error);
            } finally {

                this.loading = false;
            }
        },
        groupClubsByCategory(clubs) {
            const categories = {};
            clubs.forEach(club => {
                if (!categories[club.category_id]) {
                    categories[club.category_id] = {
                        category_name: club.category_name,
                        clubs: []
                    };
                }
                categories[club.category_id].clubs.push(club);

            });
            this.clubCategories = Object.values(categories);
        },
        async fetchRegisteredClubs() {
            try {
                const response = await axios.get('/get-registered-clubs');
                this.alreadyRegisteredClubs = response.data.map((club) => club.id);
                this.selectedClubs = [...this.alreadyRegisteredClubs];
            } catch (error) {
                console.error("Error Fetching registered clubs: ", error);
            }
        },
        async registerClubs() {
            if (!this.exceedsClubLimit && this.selectedClubs.length > 0 && !this.categoryError) {
                try {
                    await axios.post('/register/clubs', {
                        clubs: this.selectedClubs,
                        class_level: this.form.class_level,
                    });
                    await Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Clubs registered successfully!',
                        confirmButtonText: 'OK'
                    });
                    this.selectedClubs = [];
                    this.getClubs();
                    this.fetchRegisteredClubs();
                    location.reload();

                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Error registering for clubs',
                        confirmButtonText: 'Try Again'
                    });
                }
            }
        },
        checkCategory1(category_id) {
            const selectedClubsInCategory = this.selectedClubs.filter(clubId => {
                const clubCategory = this.clubCategories.find(category => category.clubs.some(c => c.id === clubId && c.category_id === category_id));
                return clubCategory !== undefined;
            });
            if (selectedClubsInCategory.length > 1) {
                this.categoryError = "You can only select one club from each category.";
                //this.selectedCategories.delete(category_id);
            } else {
                this.categoryError = '';
                //this.selectedCategories.add(category_id);
            }
        },

        checkCategory() {
            const categoryCount = {};
            this.selectedClubs.forEach(clubId => {
                const club = this.clubCategories.flatMap(category => category.clubs)
                    .find(club => club.id === clubId);

                if (club) {
                    categoryCount[club.category_id] = (categoryCount[club.category_id] || 0) + 1;
                }
            });
            const hasCategoryConflict = Object.values(categoryCount).some(count => count > 1);

            if (hasCategoryConflict) {
                this.categoryError = "You can only select one club from each category.";
            } else {
                this.categoryError = '';
            }
        },
    },
    watch: {
        selectedClubs() {
            this.categoryError = '';
            this.selectedClubs.forEach(clubId => {
                const club = this.clubs.find(c => c.id === clubId);
                if (club) {
                    this.checkCategory(club.category_id)
                }
            });
        }
    },

    created() {
        this.getClubs();
        this.fetchRegisteredClubs();
    },
    computed: {
        exceedsClubLimit() {
            const totalSelectedClubs = new Set([...this.selectedClubs, ...this.alreadyRegisteredClubs]).size;
            return totalSelectedClubs > 2;
        }
    }
}

</script>
<style scoped>
.error {
    color: red;
}
</style>