<template>
    <main>
        <div class="container">
            <section
                class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div class="card mb-3">
                                <div class="card-body">
                                    <div class="pt-4 pb-2">
                                        <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                    </div>
                                    <form @submit.prevent="login" class="row g-3 needs-validation" novalidate>
                                        <div class="col-12">
                                            <label for="yourUsername" class="form-label">Email</label>
                                            <div class="input-group has-validation">
                                                <input type="text" v-model="email" class="form-control" id="email"
                                                    required>
                                            </div>
                                            <span v-if="errors.email" class="text-danger">{{ errors.email[0]
                                                }}</span>
                                        </div>
                                        <div class="col-12">
                                            <label for="yourPassword" class="form-label">Password</label>
                                            <input type="password" v-model="password" class="form-control" id="password"
                                                required>
                                            <div class="invalid-feedback">Please enter your password!</div>
                                            <span v-if="errors.password" class="text-danger">{{ errors.password[0]
                                                }}</span>
                                        </div>

                                        <div class="col-12">
                                            <button class="btn btn-primary w-100" type="submit" :disabled="loading">
                                                <span v-if="loading" class="spinner-border spinner-border-sm"
                                                    role="status" aria-hidden="true"></span>
                                                <span v-if="!loading">Login</span>
                                            </button>
                                            <div v-if="serverError" class="text-danger mt-2">{{ serverError }}</div>
                                        </div>
                                        <div class="col-12">
                                            <p class="small mb-0">Don't have account? <a href="/register">Create an
                                                    account</a></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main><!-- End #main -->
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            email: "",
            password: "",
            errors: {},
            serverError: '',
            loading: false,
        }
    },
    methods: {
        async login() {
            this.loading = true;
            this.serverError = '';
            this.errors = {};
            try {

                const response = await axios.post('/sign-in', {
                    email: this.email,
                    password: this.password,
                });

                console.log('Login success:', response);

                const token = response.data.token;
                const user_role = response.data.role;
                localStorage.setItem('token', token);
                localStorage.setItem('user_role', user_role);
                const redirectPath = response.data.redirect.replace(/\\\//g, '/');

                if (response.status === 200) {
                    this.$router.push(redirectPath);
                }

            } catch (error) {
                this.loading = false;
                if (error.response && error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                } else if (error.response && error.response.status === 401) {
                    this.serverError = error.response.data.message || "Incorrect email or password.";
                } else {
                    this.serverError = 'An error occured. Please try again later.'
                }
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
<style>
.error {
    color: red;
}
</style>