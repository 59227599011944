<template>
    <div class="pagetitle">
        <h1>Dashboard</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active">Dashboard</li>
            </ol>
        </nav>
    </div><!-- End Page Title -->
    <section class="section dashboard">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-xxl-4 col-md-4">
                        <div class="card info-card sales-card">
                            <div class="card-body">
                                <h5 class="card-title"> <span>Students</span></h5>
                                <div class="d-flex align-items-center">
                                    <div
                                        class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                        <i class="bi bi-people"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{{ this.dashboardData.students }}</h6>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div><!-- End Sales Card -->

                    <!-- Revenue Card -->
                    <div class="col-xxl-4 col-md-4">
                        <div class="card info-card revenue-card">
                            <div class="card-body">
                                <h5 class="card-title"> <span> Clubs</span></h5>
                                <div class="d-flex align-items-center">
                                    <div
                                        class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                        <i class="bi bi-list-stars"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{{ this.dashboardData.clubs }}</h6>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div><!-- End Revenue Card -->

                    <div class="col-xxl-4 col-md-4">
                        <div class="card info-card revenue-card">
                            <div class="card-body">
                                <h5 class="card-title"> <span> Sports</span></h5>

                                <div class="d-flex align-items-center">
                                    <div
                                        class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                        <i class="bi bi-music-player"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{{ this.dashboardData.sports }}</h6>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            clubs: [],
            attendance: {},
            dashboardData: [],
        };
    },
    methods: {
        async fetchClubs() {
            try {
                const response = await axios.get('/club/mentor/list');
                this.clubs = response.data;
                // Initialize attendance for students
                this.clubs.forEach(club => {
                    club.students.forEach(student => {
                        this.attendance[student.id] = false;
                    });
                });
            } catch (error) {
                console.error('Error fetching clubs:', error);
            }
        },
        async saveAttendance(clubId) {
            try {
                const attendanceData = {
                    club_id: clubId,
                    attendance: this.attendance
                };
                await axios.post('/api/mentor/attendance', attendanceData);
                alert('Attendance saved successfully');
            } catch (error) {
                console.error('Error saving attendance:', error);
            }
        },
        async getDashboardData() {
            let response = await axios.get('/counts/');
            this.dashboardData = response.data;

        },
    },
    mounted() {
        this.fetchClubs();
        this.getDashboardData();
    }
};
</script>

<style scoped>
.club-section {
    margin-bottom: 20px;
}
</style>
