<template>
    <section class="section profile">
        <div class="row mt-3">
            <div class="col-xl-8">
                <div class="card">
                    <div class="card-body pt-3">
                        <!-- Bordered Tabs -->
                        <ul class="nav nav-tabs nav-tabs-bordered">
                            <li class="nav-item">
                                <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Update your
                                    Profile</button>
                            </li>
                        </ul>
                        <div class="tab-content pt-2">
                            <div class="tab-pane fade show active profile-edit pt-3" id="profile-edit">

                                <!-- Profile Edit Form -->
                                <form @submit.prevent="completeProfile">
                                    <div class="row mb-3">
                                        <label for="fullName" class="col-md-4 col-lg-3 col-form-label">First
                                            Name</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="firstname" type="text" class="form-control" id="fullName"
                                                placeholder="e.g: Janet">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Last Name</label>
                                        <div class="col-md-8 col-lg-9">
                                            <input v-model="lastname" type="text" class="form-control" id="fullName"
                                                placeholder="e.g: Ingabire">
                                        </div>
                                    </div>
                                    <div>
                                        <button type="submit" class="btn btn-primary">Update Profile</button>
                                    </div>
                                </form><!-- End Profile Edit Form -->
                                <p v-if="ermsg">{{ ermsg }}</p>
                            </div>
                        </div><!-- End Bordered Tabs -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            firstname: '',
            lastname: '',
            ermsg: '',
        };
    },
    methods: {
        async completeProfile() {
            try {
                const response = await axios.post('/complete-profile', {
                    firstname: this.firstname,
                    lastname: this.lastname,
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                }
                this.$router.push(response.data.redirect);
            } catch (error) {
                console.log("Error occurred: ", error);
                if (error.response && error.response.data.errors) {
                    this.ermsg = error.response.data.errors;
                } else if (error.response && error.response.data.message) {
                    this.ermsg = error.response.data.message;
                } else {
                    this.ermsg = 'An error occurred.';
                }
            }
        }
    }
}
</script>