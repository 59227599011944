import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './axios'

import '@/assets/vendor/bootstrap/css/bootstrap.min.css';
import '@/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '@/assets/vendor/apexcharts/apexcharts.min.js';
import '@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js';
import '../src/assets/css/style.css';


const app = createApp(App);


app.use(store).use(router).mount('#app')
